import "core-js/modules/es.array.push.js";
import { baseActivePage, sendBazeFamilyNotice, getTeacherList, serverActiveRelease } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      goodsCodeValue: [],
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      dialogFormVisibleImg: false,
      activeWxQeordImg: '',
      pageInfo: {
        pageSize: 10,
        server_active_name: '',
        type: '',
        current: 1,
        server_active_state: '',
        total: 0
      },
      teacherList: [],
      teacher_json: [],
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.server_active_name = '';
      this.pageInfo.server_active_state = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    closeDiv() {
      this.dialogFormVisible = false;
      this.dialogFormVisibleImg = false;
    },
    seleType(e) {
      this.pageInfo.active_goods_type_code1 = e[0] || '';
      this.pageInfo.active_goods_type_code2 = e[1] || '';
    },
    getList() {
      baseActivePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getTeacherList() {
      getTeacherList().then(res => {
        this.teacherList = res.data;
      });
    },
    showEdit(id, x) {
      if (x == 1) {} else {
        x = '';
      }
      this.$root.useRouter.push({
        path: "/base/baseActive/activeBaseEdit",
        query: {
          id: id,
          is_info: x
        }
      });
    },
    showRend(id) {
      let that = this;
      this.$root.ElMessageBox.confirm('活动发布后将不能修改活动信息，你确定要继续发布吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        serverActiveRelease({
          id: id
        }).then(res => {
          if (res.success) {
            that.$root.ElMessage.success(res.msg);
            that.getList();
            if (res.data) {
              that.showActiveQrcodeImg(res.data);
            }
          } else {
            that.$root.ElMessage.error(res.msg);
          }
          //that.$root.msgResult(res,'',that,'getList');
        });
      });
    },
    showActiveQrcodeImg(url) {
      this.dialogFormVisibleImg = true;
      this.activeWxQeordImg = url;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    showNotice(id, teacher_json) {
      this.dialogFormVisible = true;
      this.active_id = id;
      if (teacher_json) {
        this.teacher_json = JSON.parse(teacher_json);
      }
    },
    subTeacherNotice() {
      sendBazeFamilyNotice({
        id: this.active_id,
        teacher_json: this.teacher_json
      }).then(res => {
        this.$root.msgResult(res, '', this, 'getList');
        this.dialogFormVisible = false;
      });
    }
  }
};